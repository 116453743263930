import { serialize } from 'object-to-formdata';
import { getAuthParam, getAuthToken } from '../../lib/railsFetch';

const trackingEventQueue = ({ maxBatchSize, processQueue }) => {
  let queue = [];

  function getPathName() {
    return window.location.pathname;
  }

  const eventPayload = (e) => {
    const pathName = getPathName();
    const eventTime = new Date().toUTCString();

    return {
      eventName: e.detail.eventName,
      payload: {
        ...e.detail.payload,
        pathName,
        eventTime,
      },
    };
  };

  const push = (e) => {
    queue.push(eventPayload(e));

    if (length() >= maxBatchSize) {
      processQueue();
    }
  };

  function payload() {
    const data = {};
    data[getAuthParam()] = getAuthToken();
    data.payload = queue;
    return serialize(data);
  }

  const clear = () => {
    queue = [];
  };

  const empty = () => queue.length === 0;

  const length = () => queue.length;

  return {
    clear,
    empty,
    length,
    payload,
    push,
  };
};

export default trackingEventQueue;
