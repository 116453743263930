import containsSubstring from 'lib/containsSubstring';

// Handle any non 2xx responses by halting the chain with a rejected promise with the parsed
// JSON errors. In case the response isn't JSON, return a generic error code.
function handleResponse(response) {
  if (response.ok) {
    // HTTP status codes from 200-299, return a promise that resolves
    // with the result of parsing the body text as JSON.

    // Return an empty value if the response is 204 No Content
    if (response.status === 204) {
      return;
    }

    return response.json();
  }

  // Return a promise that is rejected with JSON error messages:
  if (response.headers.get('Content-Type').indexOf('json') !== -1) {
    return response.json()
      .then((errorJSON) => {
        // When we recieve a timeout from Heroku it comes back as a HTTP status
        // code 503.  We want to persist the code so we can note it downstream.
        if (typeof(errorJSON) === 'object') {
          errorJSON.railsFetchStatusCode = response.status; // eslint-disable-line no-param-reassign
        }
        return Promise.reject(errorJSON);
      }, () => Promise.reject({railsFetchStatusCode: response.status}));
  }

  // Things like a Rails 500 error that can't be parsed as JSON
  return Promise.reject({
    railsFetchError: `Application error code ${response.status}`
  });
}

// The fetch Web API will only consider failed fetches if there was a network-level error.
// Ie. 404 and 500 aren't considered as failed fetch requests. Therefore we should handle
// network-level errors gracefully.
function handleNetworkErrors(response) {
  return Promise.reject({railsFetchError: response.message});
}

export function getAuthToken() {
  return document.querySelector('meta[name="csrf-token"]')?.content;
}

export function getAuthParam() {
  return document.querySelector('meta[name="csrf-param"]')?.content;
}

// Generic method to interact with the backend server.
// Returns: Promise.
// * If the request is successful (HTTP codes 200-299), the consumer code can work with the JSON
//   response via `.then((json) => { ... })`.
// * If the request is unsuccessful, the consumer code handle API errors via
//   `.catch((apiError) => { ... })`.
// * In case of a network-level error, or a non-json response, the consumer code can also catch
//   the promise, and expect an object with a `railsFetchError` key with the reason for the error
//   via `.catch((apiError) => { apiError.railsFetchError })`.
export const jsonContentType = 'application/json; charset=utf-8';

let middlewares = [];

export function resetMiddlewares() {
  middlewares.length = [];
}

// Because Webpack chunks use JSONP,
// this module gets re-executed when imported by a chunk,
// so we need to reference a global variable on the window :/
if (typeof window !== 'undefined') {
  const key = '__railsFetchMiddlewares__';
  if (window[key]) {
    middlewares = window[key];
  } else {
    window[key] = middlewares;
  }
}

/**
 * @callback RailsFetch~middlewareHandler
 * @param {string} url
 * @param {RequestInit} options
 * @return {[string, RequestInit]}
 */
/**
 * @param {RailsFetch~middlewareHandler} middleware
 * @example
 * async function someMiddleware(url, options) {
 *   // validate your path
 *   if (url.match(/foobar/)) {
 *     return [url, {
 *       ...options,
 *       headers: {
 *         ...options.headers,
 *         'Authorization': `Bearer ${await getToken()}`
 *       }
 *     }];
 *   } else {
 *     return [url, options]
 *   }
 * }
 * useMiddleware(someMiddleware);
 */
export function useMiddleware(middleware) {
  middlewares.push(middleware);
}

export default async function railsFetch({
  url,
  method = 'get',
  data,
  contentType = jsonContentType,
  authenticityToken = getAuthToken(),
  accept = 'application/json'
}) {
  let options = {
    method,
    credentials: 'same-origin',
    headers: {
      'Accept': accept,
      'X-CSRF-Token': authenticityToken
    }
  };

  if (contentType) {
    options.headers['Content-Type'] = contentType;
  }

  if (data) {
    const isJson = contentType && containsSubstring('application/json', contentType);
    options.body = isJson ? JSON.stringify(data) : data;
  }

  for (const middleware of middlewares) {
    // eslint-disable-next-line no-param-reassign
    [url, options] = await middleware(url, options);
  }

  return fetch(url, options).then(handleResponse, handleNetworkErrors);
}
