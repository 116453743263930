import GtmTracker from '../lib/tracking/trackGtm';
import TrackingEventProcessor from '../lib/tracking/trackingEventProcessor';

const trailheadTrackEventListener = (trackerFns = []) => {
  const requireTrackerFn = () => {
    throw new Error('No tracker function passed');
  };

  if (trackerFns.length === 0) {
    requireTrackerFn();
  }

  const trackTrailheadEvent = (e) => {
    trackerFns.forEach((tracker) => tracker(e));
  };

  const attachEventListener = () => {
    document.addEventListener('trailhead_track', (e) => {
      trackTrailheadEvent(e);
    });
  };

  return {
    add: attachEventListener,
  };
};

if (process.env.NODE_ENV !== 'test') {
  const trailheadEventProcessor = new TrackingEventProcessor();
  const gtmTracker = new GtmTracker();
  const trackers = [trailheadEventProcessor.track, gtmTracker.track];
  trailheadTrackEventListener(trackers).add();
}

export default trailheadTrackEventListener;
