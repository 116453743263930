import trackingEventQueue from './trackingEventQueue';
const DEFAULT_BATCH_SIZE = 1;
const ACTIVITY_ENDPOINT = '/trailhead/activity';

class TrackingEventProcessor {
  constructor({ maxBatchSize = DEFAULT_BATCH_SIZE } = {}) {
    this.queue = trackingEventQueue({
      processQueue: this.processQueue,
      maxBatchSize,
    });
    this.attachEventListener();
  }

  attachEventListener() {
    document.addEventListener('visibilitychange', () => {
      this.processQueue({ pageEvent: 'pageVisibility' });
    });
    window.addEventListener('pagehide', () => {
      this.processQueue({ pageEvent: 'pageHide' });
    });
  }

  sendEvents() {
    navigator.sendBeacon(ACTIVITY_ENDPOINT, this.queue.payload());
    this.queue.clear();
  }

  processQueue = ({ pageEvent } = {}) => {
    if (!pageEvent) {
      this.sendEvents();
    } else if (this.shouldSendBeacon(pageEvent)) {
      this.sendEvents();
    }
  };

  shouldSendBeacon(pageEvent) {
    if (pageEvent === 'pageHide') {
      return !this.queue.empty();
    }
    return document.visibilityState === 'hidden' && !this.queue.empty();
  }

  track = (e) => {
    this.queue.push(e);
  };
}

export default TrackingEventProcessor;
