/* eslint-disable no-undef */
function pushToDataLayer(payload) {
  if (typeof window.dataLayer === 'undefined') {
    window.dataLayer = [];
  }
  window.dataLayer.push(payload);
}

export function transformPayload(payload) {
  return {
    detail: {
      eventName: 'eventTracker',
      payload,
    },
  };
}

export default class GtmTracker {
  track = (e) => {
    const pathName = window.location.pathname;

    pushToDataLayer({
      event: e.detail.eventName,
      payload: {
        ...e.detail.payload,
        pathName,
      },
    });
  };
}
